<footer class="footer text-white">
    <!-- Réinsurance  -->
    <div class=" bg-white">
        <div class="container md:py-6 md:px-20">
            <div class="container mx-auto flex flex-col md:flex-row md:justify-between  md:items-center">
                <div *ngFor="let item of iconsReinsurance" class="flex flex-row items-center mb-6 md:mb-0 mx-4">
                    <img [src]="'assets/icons/footer/' + item.icon" alt="{{ item.name }}" class="h-12 w-12 mr-4">
                    <span class="text-gray-900 text-start max-w-xs break-words">{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Main footer -->
    <div class=" bg-footerGrey">
        <div class="container md:px-20 py-4">
            <!-- desktop view -->
            <div class="row mt-lg-5">
                <div class="d-none d-lg-block col-lg">
                    <div class="">
                        <img class="h-auto w-4/5" src="assets/OXYLIO-LOGO2021-BLANC.webp" loading="lazy" width="200"
                            height="49" alt="Logo Oxylio">
                    </div>
                    <div class="my-3 mt-3 font-light">
                        Le concessionnaire indépendant, au service des particuliers depuis 2004.
                    </div>
                    <div class="flex">
                        <a class="mr-1 iconRS" id="facebook" aria-label="facebook"
                            href="https://www.facebook.com/OxylioGroupe" target="_blank"
                            rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="instagram" aria-label="instagram"
                            href="https://www.instagram.com/oxylio/" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="tiktok" aria-label="tiktok"
                            href="https://www.tiktok.com/@_oxylio" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="linkedin" aria-label="linkedin"
                            href="https://www.linkedin.com/company/oxylio" target="_blank"
                            rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="youtube" aria-label="youtube"
                            href="https://www.youtube.com/@OxylioGroupe" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                        </a>
                    </div>
                    <div class="bg-white mt-5 rounded-full py-1 px-3">
                        <script src="https://www.garagescore.com/seo/group/639838669ba72900040eda19/enrich.js"></script>
                        <iframe name="garagescore" loading="lazy" sandbox scrolling="no"
                            src="https://widget.garagescore.com/widget/group/639838669ba72900040eda19/banner?size=xsmall&background=true&locale=fr-fr&brand=garagescore"
                            style="width:353px;height:39px;overflow:hidden;"></iframe>
                    </div>
                </div>
                <div class="d-none d-lg-block col-lg">
                    <h5 class="text-base mb-2.5 font-medium">Acheter</h5>
                    <ul class="list-unstyled leading-[1.5]">
                        <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, petits_prix: 1 }" (click)="setFilterPetitsPrix()">
                            Petits prix
                            </a>
                        </li>
                       <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, faibles_km: 1 }" (click)="setFilterFaibleKm()" >
                                Faibles kilométrages
                            </a>
                        </li>
                         <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, occasions_recentes: 1 }" (click)="setFilterOccasionsRecentes()" >
                                Occasions récentes
                            </a>
                        </li>
                         <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, carburants: ['Hybride', 'Electrique']}" (click)="setFiltersCarburants(['Hybride', 'Electrique'])" >
                                Électriques & Hybrides
                            </a>
                        </li>
                         <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: 'Promotion'}" (click)="setFiltersOffers(['Promotion'])">
                                Prix en baisse
                            </a>
                        </li>
                         <!--<li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: 'Prime conversion'}" (click)="setFiltersOffers(['Prime conversion'])">
                                Prime conversion
                            </a>
                        </li>-->
                         <!--<li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: ['Promotion', 'Prime conversion']}" (click)="setFiltersOffers(['Promotion', 'Prime conversion'])">
                                Offres en cours
                            </a>
                        </li>-->
                    </ul>

                </div>
                <div class="d-none d-lg-block col-lg">
                    <h5 class="text-base mb-2.5 font-medium">Services</h5>
                    <ul class="list-unstyled leading-[1.5]">
                         <li>
                            <a class="link" routerLink="/informations/services/achat-en-ligne">Achat en ligne</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/reprise">Reprise</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/financement">Financement</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/services/livraison">Livraison</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/achat/Reconditionnement">Reconditionnement</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/garantie-voiture-neuve-occasion">Garantie</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/commande-voiture-sur-mesure">Commande sur mesure</a>
                        </li>
                           <!-- <li>
                            <a class="link" routerLink="/informations/services/essai-routier">Essai routier</a>
                        </li> -->
                        <li>
                            <a class="link" routerLink="/service-apres-vente">Service après-vente</a>
                        </li>
                    </ul>
                </div>
                <div class="d-none d-lg-block col-lg">
                    <h5 class="text-base mb-2.5 font-medium">Agences</h5>
                    <ul class="list-unstyled leading-[1.5]">
                        <li>
                            <a class="link" routerLink="/agence/34-montpellier">Oxylio Montpellier</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/30-nimes">Oxylio Nîmes</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/31-toulouse">Oxylio Toulouse</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/34-st-andre-de-sangonis">Oxylio St André de Sangonis</a>
                        </li>
                    </ul>
                </div>
                <div class="d-none d-lg-block col-lg">
                    <h5 class="text-base mb-2.5 font-medium">Groupe</h5>
                    <ul class="list-unstyled leading-[1.5]">
                        <li>
                            <a class="link" routerLink="/informations/oxylio/qui-sommes-nous">Notre histoire</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/oxylio/les-10-engagements-oxylio-1">Nos engagements</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/oxylio/la-charte-voc">La charte VOC</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/recrutement" target="_blank">Recrutement</a>
                        </li>
                        <li>
                            <a class="link" href="https://blog.oxylio.com/" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/faq">FAQ</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Mobile view -->

            <div class="d-lg-none bg-footerGrey">
                <img class="h-auto w-4/5 mb-2 mx-auto" src="assets/OXYLIO-LOGO2021-BLANC.webp" loading="lazy"
                    width="218" height="52" alt="Logo Oxylio">
                <div class="my-3 mt-3 font-light w-4/5 mx-auto">
                    Le concessionnaire indépendant, <br> au service des particuliers depuis 2004.
                </div>


                <div class="w-4/5 mx-auto">
                    <h5 class="text-base font-semibold text-white mt-4">Acheter</h5>
                    <ul class="list-unstyled leading-6">
                            <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, petits_prix: 1 }" (click)="setFilterPetitsPrix()">
                            Petits prix
                            </a>
                        </li>
                        <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, faibles_km: 1 }" (click)="setFilterFaibleKm()" >
                                Faibles kilométrages
                            </a>
                        </li>
                            <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, occasions_recentes: 1 }" (click)="setFilterOccasionsRecentes()" >
                                Occasions récentes
                            </a>
                        </li>
                            <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, carburants: ['Hybride', 'Electrique']}" (click)="setFiltersCarburants(['Hybride', 'Electrique'])" >
                                Électriques & Hybrides
                            </a>
                        </li>
                            <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: 'Promotion'}" (click)="setFiltersOffers(['Promotion'])">
                                Prix en baisse
                            </a>
                        </li>
                        <!--    <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: 'Prime conversion'}" (click)="setFiltersOffers(['Prime conversion'])">
                                Prime conversion
                            </a>
                        </li>-->
                        <!--    <li class="font-normal">
                            <a class="link"
                                routerLink="/achat/vehicules" [queryParams]="{from: 0, offers: ['Promotion', 'Prime conversion']}" (click)="setFiltersOffers(['Promotion', 'Prime conversion'])">
                                Offres en cours
                            </a>
                        </li>-->
                    </ul>

                    <h5 class="text-base text-white mt-4">Services</h5>
                    <ul class="list-unstyled leading-6">
                        <li>
                            <a class="link" routerLink="/informations/services/achat-en-ligne">Achat en ligne</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/reprise">Reprise</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/financement">Financement</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/services/livraison">Livraison</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/achat/Reconditionnement">Reconditionnement</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/garantie-voiture-neuve-occasion">Garantie</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/commande-voiture-sur-mesure">Commande sur mesure</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/service-apres-vente">Service après-vente</a>
                        </li>
                    </ul>

                    <h5 class="text-base text-white mt-4">Agences</h5>
                    <ul class="list-unstyled leading-6">
                        <li>
                            <a class="link" routerLink="/agence/34-montpellier">Oxylio Montpellier</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/30-nimes">Oxylio Nîmes</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/31-toulouse">Oxylio Toulouse</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/agence/34-st-andre-de-sangonis">Oxylio St André de Sangonis</a>
                        </li>
                    </ul>

                    <h5 class="text-base text-white mt-4">Groupe</h5>
                    <ul class="list-unstyled leading-6">
                        <li>
                            <a class="link" routerLink="/informations/oxylio/qui-sommes-nous">Notre histoire</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/oxylio/les-10-engagements-oxylio-1">Nos engagements</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/oxylio/la-charte-voc">La charte VOC</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/recrutement" target="_blank">Recrutement</a>
                        </li>
                        <li>
                            <a class="link" href="https://blog.oxylio.com/" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/informations/faq">FAQ</a>
                        </li>
                        <li>
                            <a class="link" routerLink="/contact">Contact</a>
                        </li>
                    </ul>

                    <!-- social media  -->
                    <div class="flex mt-3">
                        <a class="mr-1 iconRS" id="facebook" aria-label="facebook"
                            href="https://www.facebook.com/OxylioGroupe" target="_blank"
                            rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="instagram" aria-label="instagram"
                            href="https://www.instagram.com/oxylio/" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="tiktok" aria-label="tiktok"
                            href="https://www.tiktok.com/@_oxylio" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="linkedin" aria-label="linkedin"
                            href="https://www.linkedin.com/company/oxylio" target="_blank"
                            rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon>
                        </a>
                        <a class="mx-1 iconRS" id="youtube" aria-label="youtube"
                            href="https://www.youtube.com/@OxylioGroupe" target="_blank" rel="external noopener noreferrer">
                            <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                        </a>
                    </div>
                </div>
                <div class="bg-white my-3 rounded-full py-1 px-3">
                        <script src="https://www.garagescore.com/seo/group/639838669ba72900040eda19/enrich.js"></script>
                        <iframe name="garagescore" loading="lazy" sandbox scrolling="no"
                            src="https://widget.garagescore.com/widget/group/639838669ba72900040eda19/banner?size=xsmall&background=true&locale=fr-fr&brand=garagescore"
                            style="width:353px;height:39px;overflow:hidden;"></iframe>
                    </div>
            </div>
        </div>
    </div>

    <div class="row text-center font-light mx-0">
        <div class="col mt-lg-0 bg-white text-footerGrey">
            <ul class="my-3">
                <li *ngFor="let marque of marques; let isLast = last" class="list-inline-item marques text-sm">
                    <a class="cursor-pointer text-footerGrey font-light" routerLink="/achat/vehicules"
                        [queryParams]="{marques: [marque]}" (click)="setFiltersMarque([marque])">{{ marque }} {{ isLast ? '' : '&nbsp;|&nbsp;' }}</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="bg-white text-footerGrey">
        <div class="container px-4 py-3 text-center font-normal border-t border-t-footerGrey">
            <span class="text-sm">&copy; {{copyright}} Oxylio tous droits réservés | </span>
            <a class="text-footerGrey text-sm pr-1" routerLink="/informations/liens-utiles/mentions-legales">Mentions
                légales |&nbsp;</a>
            <a class="text-footerGrey text-sm pr-1" routerLink="/informations/liens-utiles/cgv">CGV |&nbsp;</a>
            <a class="text-footerGrey text-sm pr-1"
                routerLink="/informations/liens-utiles/politique-de-confidentialite-d-oxylio">Politique de
                confidentialité |&nbsp;</a>
            <button class="text-footerGrey border-none bg-white text-sm" (click)="trackingConsentModification()">Gestion
                des cookies |&nbsp;</button>
            <span class="text-sm">Pensez à covoiturez #SeDéplacerMoinsPolluer</span>
        </div>
    </div>
</footer>
