import {
  Component,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StaticService } from '../services/static.service';
import { PlatformService } from '../services/platform.service';
import { Accounts } from 'src/app/core/services/accounts.service';
import { DOCUMENT } from '@angular/common';
import { FilteringService } from 'src/app/search/filtering.service';
import { ModalNotConnetedComponent } from 'src/app/shared/components/modal-not-conneted/modal-not-conneted.component';
import { ICurrentUser } from 'src/app/shared/interfaces/ICurrentUser';
import { SearchService } from '../services/search.service';
import { LogoService } from '../services/logo.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faUserChecked } from './custom-icon/custom-icon';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() toggleMenu = new EventEmitter<any>();

  isHovering = false;

  menuGroups = [];
  marques: string[];
  modeles: string[];

  categories: string[];

  private pltSubscription: Subscription;

  isMobile = true;
  isAuth: boolean;
  firstname: string;
  currentUser: ICurrentUser;
  state: string;
  ranges: any;

  // for the logo
  logoUrl: string;
  logoAlt: string;

  constructor(
    private staticService: StaticService,
    public dialog: MatDialog,
    private platformService: PlatformService,
    private searchService: SearchService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    private filteringService: FilteringService,
    private account: Accounts,
    private router: Router,
    private logoService: LogoService,
    private library: FaIconLibrary
  ) {
    this.library.addIcons(faUserChecked);
    this.pltSubscription = this.platformService
      .getIsMobile()
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      });
  }

  async ngOnInit() {
    try {
      void (await firstValueFrom(this.account.isAuthentified()));
      this.account
        .getIsConnected()
        .subscribe((isConnected) => (this.isAuth = isConnected));
      this.account
        .getCurrentUser()
        .subscribe(
          (currentUser: ICurrentUser) => (this.currentUser = currentUser)
        );
      this.marques = (
        await firstValueFrom(this.searchService.search({}, 'popover'))
      ).marques.slice(0, 7);
      this.ranges = (
        await firstValueFrom(
          this.searchService.search({ marques: this.marques }, 'popoverModele')
        )
      ).modeles.slice(0, 7);
      // this.categories = (await firstValueFrom(this.searchService.search({}, 'popoverCategorie'))).categories;

      // if (this.categories) {
      //   const orderMarketing = [
      //     "Citadine",
      //     "Compacte",
      //     "Berline",
      //     "Break",
      //     "SUV",
      //     "Monospace",
      //     "Utilitaire"
      //   ];
      //   this.categories.sort((a, b) => {
      //     return orderMarketing.indexOf(a) - orderMarketing.indexOf(b);
      //   });
      // }

      this.categories = [
        'Citadine',
        'Compacte',
        'SUV',
        'Berline',
        'Break',
        'Monospace',
        'Utilitaire',
      ];

      const groups = await this.staticService.getGroups().toPromise();
      if (groups) {
        this.menuGroups = groups.filter((it) => it.header);
      }

      const LogoData = this.logoService.getLogoData();
      this.logoUrl = LogoData.fileName;
      this.logoAlt = LogoData.altText;
    } catch (error) {
      console.error(error);
    }
  }

  setFiltersMarque(filter) {
    this.filteringService.storeFilters = { marques: filter };
    this.filteringService.getVehicles();
    this.state = '';
  }

  setFiltersRanges(filter) {
    this.filteringService.storeFilters = {
      marques: [filter.marque],
      ranges: [filter.range],
      modeles: filter.modeles,
    };
    this.filteringService.getVehicles();
    this.state = '';
  }

  setFiltersCategories(filter) {
    this.filteringService.storeFilters = { categories: [filter] };
    this.filteringService.getVehicles();
    this.state = '';
  }

  isAuthentified() {
    /*this.account.isAuthentified().subscribe(data => {
      this.isAuth = data;
    });*/
  }

  signedOut() {
    this.account.signOut();
    this.isAuth = false;
  }

  ngOnDestroy() {
    this.pltSubscription.unsubscribe();
  }

  goToBookmarkList() {
    if (this.isAuth && this.isAuth !== undefined) {
      this.router.navigateByUrl('/account/tableau-de-bord/favoris');
    } else if (!this.isAuth || this.isAuth == undefined) {
      this.dialog.open(ModalNotConnetedComponent);
    }
  }

  setPopover(state) {
    this.state = state;
  }

  goCatalogAndResetFilters() {
    this.filteringService.storeFilters = { from: 0 };
    this.filteringService.setUrl({});
    this.filteringService.getVehicles();
    this.state = '';
    this.router.navigateByUrl('/achat/vehicules');
  }

  goOurServices() {
    this.state = '';
    this.router.navigateByUrl('/informations/services');
  }

  goHome() {
    this.filteringService.storeFilters = { from: 0 };
    this.filteringService.setUrl({});
    this.filteringService.getVehicles();
    this.state = '';
    this.router.navigateByUrl('/');
  }

  // function for link rubrique
  setFilter(type: string, value: any) {
    const filterObject = {};
    filterObject[type] = value;

    this.filteringService.storeFilters = filterObject;
    this.filteringService.getVehicles();
    this.state = '';
  }

  onHover(state: boolean) {
    this.isHovering = state;
  }
}
