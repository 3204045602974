import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

export const faUserChecked: IconDefinition = {
  prefix: 'fas' as IconPrefix,
  iconName: 'user-checked' as IconName,
  icon: [
    18, 18, [], '', 
    'M7.703 8.907C9.905 8.907 11.69 7.122 11.69 4.92C11.69 2.719 9.905 0.934 7.703 0.934C5.502 0.934 3.717 2.719 3.717 4.92C3.717 7.122 5.502 8.907 7.703 8.907Z M12.363 10.669C11.223 9.537 9.68 8.903 8.073 8.906H7.332C5.712 8.906 4.159 9.55 3.013 10.695C1.868 11.84 1.225 13.394 1.225 15.013V16.88H14.18V16.588 M14.139 13.0003L14.795 13.656C14.843 13.704 14.9 13.743 14.964 13.769C15.027 13.795 15.095 13.809 15.163 13.809C15.231 13.809 15.299 13.795 15.362 13.769C15.425 13.743 15.483 13.704 15.531 13.656L17.211 11.976'
  ]
};
